import styled from '@emotion/styled';

type ContainerProps = { orientation: string };

export const FaqContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.SPACING.XL};
  text-align: center;
  overflow-wrap: anywhere;

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const Faq = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: 60px;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    flex-direction: column;
  }
  ${({ orientation }) =>
    orientation === 'HORIZONTAL' &&
    `
    display: flex;
    
  `}
  h5 {
    margin: ${({ theme }) => theme.SPACING.S10} 0;
  }
  h2 {
    margin-top: 20px;
    margin-bottom: ${({ theme }) => theme.SPACING.SM};
  }
`;

export const Question = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${({ theme }) => theme.SPACING.S24};
`;
